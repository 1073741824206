// Import libraries
import React, { useState } from "react";

// Import components
import { Col, Row } from 'react-bootstrap';
import { DASH_ALG_WerknemersInDeSector } from "../Models/ApiModels";
import { FormatString } from "../Functions/ApiFunctions";
import { ApiBaseURL, ApiUrls } from "../Urls/ApiUrls";

function FAQ(params: any) {

    return (
        <>
            <Row className="pageBreak" Style="font-size: 12px !important;">
                <Col xl={1} md={12} className="col-print-1 header" Style="text-align: right;" id="FAQ-1">
                    <i>(1*)</i>
                </Col>
                <Col xl={11} md={12} className="col-print-11 header">
                    <i>
                        <b>Unieke werknemers/werkgevers met regeling:</b>
                        <br />
                        Dit zijn het aantal unieke werknemers/werkgevers van de sector die 1 of meerdere aanvragen op een regeling hebben. Ze worden hier dus maar 1 keer sector breed geteld, ook al hebben ze meerdere aanvragen per regeling.
                        <br />
                        Voorbeeld: Werknemer Jan heeft 1 aanvraag bij de regeling Cursussen en 1 aanvraag bij de regeling Scholingsvoucher. Jan wordt 1 keer geteld
                    </i>
                </Col>
            </Row>
            <Row Style="font-size: 12px !important;">
                <Col xl={1} md={12} className="col-print-1 header" Style="text-align: right;" id="FAQ-2">
                    <i>(2*)</i>
                </Col>
                <Col xl={11} md={12} className="col-print-11 header">
                    <i>
                        <b>Unieke werknemers/werkgevers per regeling:</b>
                        <br />
                        Dit zijn het aantal unieke werknemers/werkgevers die een aanvraag op een regeling binnen de sector hebben. Hier worden ze dus per regeling geteld en kunnen ze sector breed vaker voorkomen.
                        <br />
                        Voorbeeld: Werknemer Jan heeft 1 aanvraag bij de regeling Cursussen en 1 aanvraag bij de regeling Scholingsvoucher. Jan wordt 2x geteld.
                    </i>
                </Col>
            </Row>
            <Row Style="font-size: 12px !important;">
                <Col xl={1} md={12} className="col-print-1 header" Style="text-align: right;" id="FAQ-3">
                    <i>(3*)</i>
                </Col>
                <Col xl={11} md={12} className="col-print-11 header">
                    <i>
                        <b>Percentage unieke werknemers/werkgevers met regeling :</b>
                        <br />
                        De pijl naar boven/beneden met het percentage is op basis van de aantallen van vorig jaar per 31-12.
                        <br /> <br />

                        Voorbeeld: Sector Paddenstoelen - Unieke werknemers met regelingen 
                        <br />
                        31-12-2023: 19
                        <br />
                        2024 Q1/Q2: 11 (-42%) 
                        <br />
                        Berekening: 19-11 = 8 ; (8 / 19) * 100% = 42%
                        <br /> <br />

                        31-12-2023: 19
                        <br />
                        2024 Q3/Q4: 34 (+79%)
                        <br />
                        Berekening: 19-34 = 15 ; (15 / 19) *100%  = 79%
                    </i>
                </Col>
            </Row>
            <Row Style="font-size: 12px !important;">
                <Col xl={1} md={12} className="col-print-1 header" Style="text-align: right;" id="FAQ-4">
                    <i>(4*)</i>
                </Col>
                <Col xl={11} md={12} className="col-print-11 header">
                    <i>
                        <b>Unieke werknemers/werkgevers in sector:</b>
                        <br />
                        Deze gegevens worden opgehaald uit de laatst aangeleverde lijst van TKP (of de lijst die actief was op het moment van het bevriezen van de gegevens).
                    </i>
                </Col>
            </Row>
            <Row Style="font-size: 12px !important;">
                <Col xl={1} md={12} className="col-print-1 header" Style="text-align: right;" id="FAQ-5">
                  
                </Col>
                <Col xl={11} md={12} className="col-print-11 header">
                    <i>
                        <b>Aantallen en bedragen op basis van toezeggingen/goedgekeurd:</b>
                        <br />
                        Alle aantallen en bedragen gaan over alle aanvragen die de fase "Declaratie goedgekeurd" of "Steekproef goedgekeurd" hebben of hebben gehad in de opgehaalde periode.
                        Dit zegt niet op welke datum de aanvraag is ingediend.
                    </i>
                </Col>
            </Row>
        </>
    )
}

export default FAQ;
