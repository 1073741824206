// Import components
import { Col, Row } from 'react-bootstrap';
import EmployeesInSector from "./Base/EmployeesInSector";
import EmployeesWithRegeling from "./Base/EmployeesWithRegeling";
import EmployersInSector from "./Base/EmployersInSector";
import EmployersWithRegeling from "./Base/EmployersWithRegeling";
import FinanceChart from "./Base/FinanceChart";
import UniqueEmployeesFourYear from './Base/UniqueEmployeesFourYears';
import UniqueEmployersFourYear from './Base/UniqueEmployersFourYears';
import UniqueEmployeesFourYearTable from './Base/UniqueEmployeesFourYearsTable';
import UniqueEmployersFourYearTable from './Base/UniqueEmployersFourYearsTable';
import UniqueEmployeesTable from "./Base/UniqueEmployeesTable";
import CursusOpleiders from './Cursussen/CursusOpleiders';
import CursusOpleiding from './Cursussen/CursusOpleiding';
import logo from "./Images/CAS-Logo_New.png";
import PaymentsTable from './Base/PaymentsTable';
import UniqueEmployeesCombi from './Base/UniqueEmployeesCombi';
import UniqueEmployersCombi from './Base/UniqueEmployersCombi';
import CursusSectorAgesEmployeesCombi from './Cursussen/CursusSectorAgesEmployeesCombi';
import EmployersTop from './Base/EmployersTop';
import { RegelingListItem } from './Models/ApiModels';
import { FaPrint } from 'react-icons/fa';
import FAQ from './Base/FAQ';

export const CurrentSectorId = 18;
export const CurrentSectorName = "Paddenstoelenteelt";
const Regelingen: RegelingListItem[] = [
    { RegelingId: 29, Regeling: "Scholingsvouchers", ShowBlok1: false, ShowBlok2: false, ShowBlok3: false, ShowBlok4: true, ShowBlok5: true, ShowBlok6: true }
];

function Paddenstoelenteelt(params: any) {
    return (
        <div className="cas container-fluid">
            <Row className="header-row">
                <Col xl={2} md={12} className="col-print-3">
                    <img src={logo} className="headerLogo" />
                </Col>
                <Col xl={7} md={12} className="header col-print-9">
                    <h5 className="mb-0 hfont5"><b>Halfjaarmonitor CAM Regelingen | {params.Year} {params.Kw == 1 ? "Q1 en Q2" : ""}</b></h5>
                    <h1 className="hfont1">{CurrentSectorName}</h1>
                </Col>
                <Col xl={3} md={12} className="screen header-col-btn">
                    <button className="header-btn screen" onClick={() => window.print()}><FaPrint/> Afdrukken</button>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={3} xl={3} className="mb-2 col-print-3">
                    <EmployeesWithRegeling DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                </Col>
                <Col xs={12} sm={3} xl={3} className="mb-2 col-print-3">
                    <EmployeesInSector DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                </Col>
                  <Col xs={12} sm={3} xl={3} className="mb-2 col-print-3" >
                    <EmployersWithRegeling DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                  </Col>
                  <Col xs={12} sm={3} xl={3} className="mb-2 col-print-3">
                    <EmployersInSector DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                  </Col>
            </Row>
              <Row>
                  <Col xl={6} md={12} className="mb-2 col-print-6">
                      <div className="card bg-white border border-gray-200 rounded-lg shadow d-flex justify-content-between">
                          <div className="d-flex p-3">
                            <h6 className="flex-fill hfont6">Unieke werknemers per regeling <span><i><a className="faq-number" href="#FAQ-2">(2*)</a></i></span></h6>
                          </div>
                          <div>
                            <UniqueEmployeesCombi DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                          </div>
                      </div>
                  </Col>
                  <Col xl={6} md={12} className="mb-2 col-print-6">
                      <div className="card bg-white border border-gray-200 rounded-lg shadow p-3 h-auto">
                        <h6 className="hfont6">Unieke werknemers per regeling <span><i><a className="faq-number" href="#FAQ-2">(2*)</a></i></span></h6>
                        <UniqueEmployeesTable DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                      </div>
                  </Col>
                  <Col xl={6} md={12} className="mb-2 col-print-6">
                      <div className="card bg-white border border-gray-200 rounded-lg shadow d-flex justify-content-between">
                          <div className="d-flex p-3">
                            <h6 className="flex-fill hfont6">Unieke werkgevers per regeling <span><i><a className="faq-number" href="#FAQ-2">(2*)</a></i></span></h6>
                          </div>
                          <div>
                            <UniqueEmployersCombi DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                          </div>
                      </div>
                  </Col>
                  <Col xl={6} md={12} className="mb-2 col-print-6">
                      <div className="card pt-2 bg-white border border-gray-200 rounded-lg shadow">
                          <div className="d-flex p-3">
                            <h6 className="flex-fill hfont6">Aandeel &euro; toegekend</h6>
                          </div>
                          <div>
                            <FinanceChart DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                          </div>
                      </div>
                  </Col>
              </Row>
              <Row className="header-row pageBreak">
                    <Col xl={12} md={12} className="header">
                        <h1 className="hfont1">Cursussen</h1>
                    </Col>
              </Row>
              <Row>
                <UniqueEmployeesFourYear DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId="9" RegelingNaam="Cursussen" rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                <UniqueEmployersFourYear DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId="9" RegelingNaam="Cursussen" rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                <EmployersTop DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId="9" RegelingNaam="Cursussen" rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                <PaymentsTable DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId="9" RegelingNaam="Cursussen" rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
              </Row>
              <Row>
                  <Col xl={12} md={12} className="mb-2 col-print-12">
                      <div className="card bg-white border border-gray-200 rounded-lg shadow d-flex justify-content-between">
                          <div className="d-flex p-3">
                              <h6 className="flex-fill hfont6">Leeftijdsverdeling van werknemers in {params.Year}</h6>
                          </div>
                          <div>
                            <CursusSectorAgesEmployeesCombi DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId="9" rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                          </div>
                      </div>
                  </Col>
              </Row>
              <Row>
                  <Col xl={6} md={12} className="mb-2 col-print-6 pageBreak">
                      <div className="card bg-white border border-gray-200 rounded-lg shadow p-3 h-auto">
                          <h6 className="hfont6">Meest gebruikte cursusinstituten in {params.Year} </h6>
                        <CursusOpleiders DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                      </div>
                  </Col>
                  <Col xl={6} md={12} className="mb-2 col-print-6">
                      <div className="card bg-white border border-gray-200 rounded-lg shadow p-3 h-auto">
                          <h6 className="hfont6">Meest gevolgde cursussen in {params.Year} </h6>
                        <CursusOpleiding DashboardYear={params.Year} SectorId={CurrentSectorId} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} />
                      </div>
                  </Col>
              </Row>
                <>
                    {
                        Regelingen.map(reg => (
                            <div key={reg.RegelingId}>
                                <Row className="header-row pageBreak">
                                    <Col xl={12} md={12} className="header">
                                        <h1 className="hfont1">{reg.Regeling}</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    {reg.ShowBlok1 ? <UniqueEmployeesFourYear DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId={reg.RegelingId} RegelingNaam={reg.Regeling} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} /> : ""} 
                                    {reg.ShowBlok2 ? <UniqueEmployersFourYear DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId={reg.RegelingId} RegelingNaam={reg.Regeling} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} /> : ""} 
                                    {reg.ShowBlok5 ? <UniqueEmployeesFourYearTable DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId={reg.RegelingId} RegelingNaam={reg.Regeling} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} /> : ""}
                                    {reg.ShowBlok6 ? <UniqueEmployersFourYearTable DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId={reg.RegelingId} RegelingNaam={reg.Regeling} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} /> : ""} 
                                    {reg.ShowBlok3 ? <EmployersTop DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId={reg.RegelingId} RegelingNaam={reg.Regeling} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} /> : ""} 
                                    {reg.ShowBlok4 ? <PaymentsTable DashboardYear={params.Year} SectorId={CurrentSectorId} RegelingId={reg.RegelingId} RegelingNaam={reg.Regeling} rnd={params.Rnd} lg={params.Lg} kw={params.Kw} /> : ""} 
                                </Row>
                            </div>

                        ))
                    }
            </>
            <FAQ />
        </div>
  );
}

export default Paddenstoelenteelt;